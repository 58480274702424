'use strict';
$(document).ready(function() {
	var $body = $('body');
	var lang = $body.data('lang');
	var $mobileMenuToggle = $('#mobileMenuToggle');

	var cookieConsentContent = {
		allow: {
			hu: 'Elfogadom',
			en: 'Accept'
		},
		dismiss: {
			hu: 'Elutasítom',
			en: 'Deny',
		},
		link: {
			hu: 'https://optin.hu/rolunk/sutik-hasznalatarol',
			en: 'https://optin.hu/en/about/cookie-policy/'
		},
		linkText: {
			hu: 'Részletek',
			en: 'Learn more'
		},
		message: {
			hu: 'A weboldalunk helyes működéséhez a sütik használata szükséges. Kérjük engedélyezze őket az Elfogadom gombra kattintva.',
			en: 'We use cookies on this site to ensure it works correctly and you get the best experience using it. Please accept the cookies by clicking on the Accept button.'
		}
	};

	var cookieName = 'onguardCookiesAllowed';

	if (Cookies.get(cookieName) !== 'allow') {
		Cookies.remove(cookieName, { path: '/', domain: location.hostname });
		window.cookieconsent.initialise({
			palette: {
				popup: {
					background: '#000000'
				},
				button: {
					background: '#3f51b5'
				}
			},
			theme: 'edgeless',
			type: 'opt-in',
			cookie: {
				name: cookieName,
				domain: location.hostname,
			},
			content: {
				message: cookieConsentContent.message[lang],
				allow: cookieConsentContent.allow[lang],
				dismiss: cookieConsentContent.dismiss[lang],
				link: cookieConsentContent.linkText[lang],
				href: cookieConsentContent.link[lang]
			},
			onStatusChange: function (status) {
				if (status === 'allow') {
					analyticsInit();
				}
			},
		});
	}

	function analyticsInit () {
		if (Cookies.get(cookieName) === 'allow') {
			(function(i,s,o,g,r,a,m){i['GoogleAnalyticsObject']=r;i[r]=i[r]||function(){
			(i[r].q=i[r].q||[]).push(arguments)},i[r].l=1*new Date();a=s.createElement(o),
			m=s.getElementsByTagName(o)[0];a.async=1;a.src=g;m.parentNode.insertBefore(a,m)
			})(window,document,'script','https://www.google-analytics.com/analytics.js','ga');

			ga('create', 'UA-104188762-1', 'auto');
			ga('set', 'anonymizeIp', true);
			ga('send', 'pageview');
			console.log('Analytics initialised');
		}
	}
	analyticsInit();

	var scrollReveal = ScrollReveal();

	function scrollRevealInit() {
		if ($(window).width() > 1024) {
			scrollReveal.reveal('.c-scroll-reveal', {
				viewFactor: 0.5,
				duration: 500,
				mobile: false,
			});
			scrollReveal.reveal('.l-features__item', {
				viewFactor: 0.05,
				duration: 500,
				mobile: false,
			});
			scrollReveal.reveal('.l-table__row', {
				viewFactor: 0.05,
				duration: 500,
				mobile: false,
			}, 50);
		} else {
			$('.c-scroll-reveal, .l-features__item, .l-table__row').css('visibility', 'visible');
		}
	}

	scrollRevealInit();

	function mobileMenuInit() {
		$mobileMenuToggle.on('click.button', function(e) {
			e.stopPropagation();
			$body.addClass('mobile-menu--isActive');
			closeMobileMenu();
		});
	}

	$('.l-mobile-menu__link').click(function() {
		$mobileMenuToggle.off('click.button');
		$body.removeClass('mobile-menu--isActive').off('click.body');
		mobileMenuInit();
	});

	function closeMobileMenu() {
		$mobileMenuToggle.off('click.button');
		$body.one('click', function() {
			$body.removeClass('mobile-menu--isActive').off('click.body');
			mobileMenuInit();
		});
	}

	mobileMenuInit();

	function activeAnchorMenuInit() {
		var topMenu = $('#mainMenu'),
			topMenuHeight = topMenu.outerHeight() + 15,
			// All list items
			menuItems = topMenu.find('a'),
			// Anchors corresponding to menu items
			scrollItems = menuItems.map(function() {
				var item = $($(this).attr('href'));
				if (item.length) {
					return item;
				}
			});

		// Bind to scroll
		$(window).scroll(function() {
			// Get container scroll position
			var fromTop = $(this).scrollTop() + topMenuHeight;

			// Get id of current scroll item
			var cur = scrollItems.map(function() {
				if ($(this).offset().top < fromTop)
					return this;
				}
			);
			// Get the id of the current element
			cur = cur[cur.length - 1];
			var id = cur && cur.length ? cur[0].id : "";
			// Set/remove active class
			menuItems.removeClass('l-header__menu-link--isActive')
				.filter('[href="#' + id + '"]')
				.addClass('l-header__menu-link--isActive');
		});
	}

	activeAnchorMenuInit();

	function tableInit() {
		$('.l-table__grid--feature').click(function() {
			$(this).parent().toggleClass('l-table__row--isActive');
		});
	}
	tableInit();

	function formInit() {

		var contactForm = $('#form');
		//We set our own custom submit function
		contactForm.on('submit', function(e) {
			//Prevent the default behavior of a form
			e.preventDefault();
			var formResponse = $('#formResponse')
				.removeClass('l-contact__response--isSuccess l-contact__response--isError')
				.html('');

			// Check recaptcha
			if (
				$('#contactName').val().length === 0 ||
				$("#contactEmail").val().length === 0 ||
				$("#contactMessage").val().length === 0
			) {
				// formResponse.addClass('l-contact__response--isError').html(messages.fillOut);
			} else {
				//Our AJAX POST
				$.ajax({
					type: "POST",
					url: contactForm.attr('action'),
					data: {
						name: $('#contactName').val(),
						email: $("#contactEmail").val(),
						message: $("#contactMessage").val(),
						lang: lang,
						captcha: grecaptcha.getResponse()
					}
				}).done(function(data) {
					formResponse.addClass('l-contact__response--isSuccess').html(data);
				}).fail(function() {
					var errorMessage = lang === 'hu'
						? 'Hoppá! Valami probléma történt! Kérjük próbálja meg újra!'
						: 'Oops. Something went wrong. Please try again later.';
					formResponse.addClass('l-contact__response--isError').html(errorMessage);
				});
			}
		});
	};

	formInit();

	function smoothScrollInit() {
		$('a[href*="#"]:not([href="#"])').click(function() {
			if (
				location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') &&
				location.hostname == this.hostname
			) {
				var target = $(this.hash);
				target = target.length
					? target
					: $('[name=' + this.hash.slice(1) + ']');
				if (target.length) {
					$('html, body').animate({ scrollTop: target.offset().top }, 1000);
					return false;
				}
			}
		});
	}

	smoothScrollInit();

	function cardsInit() {
		var $mainButton = $('.l-features__more-button'),
			$closeButton = $('.l-features__close-button');

		$mainButton.on('click', function() {
			var $card = $(this).closest('.l-features__item').addClass('l-features__item--isActive'),
				$ripple = $card.find('.l-features__ripple').addClass('l-features__ripple--isActive'),
				$more = $card.find('.l-features__more').addClass('l-features__more--isActive'),
				$layer = $card.find('.l-features__overlay');

			setTimeout(function() {
				$layer.addClass('l-features__overlay--isActive');
			}, 800);
		});

		$closeButton.on("click", function() {
			var $card = $(this).closest('.l-features__item').removeClass('l-features__item--isActive'),
				$ripple = $card.find('.l-features__ripple').removeClass('l-features__ripple--isActive'),
				$more = $card.find('.l-features__more').removeClass('l-features__more--isActive'),
				$layer = $card.find('.l-features__overlay').removeClass('l-features__overlay--isActive');
		});
	}

	cardsInit();

	function setYear() {
		var $year = $('#year');
		var currentYear = new Date().getFullYear();
		if (parseInt($year.text()) !== currentYear) {
			$year.html(currentYear);
		}
	}

	setYear();
});
